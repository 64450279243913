import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class MessageBarService {

  textMessagesLanguage: any

  constructor(private _snackBar: MatSnackBar, private translateService: TranslateService) {
    this.getTranslation();
  }


  errorMessage(textMessage? : string){
    this._snackBar.open(<string>textMessage ?? this.textMessagesLanguage?.errorMessageBar, 'Close',{ horizontalPosition: 'end' ,  panelClass: ['alert-danger']});
  }

  successMessage(textMessage? : string){
    this._snackBar.open( <string>textMessage ?? this.textMessagesLanguage?.successMessageBar, 'Close',{ horizontalPosition: 'end' ,  panelClass: ['alert-success']});
  }

  InfoMessage(textMessage? : string){
    this._snackBar.open(<string>textMessage ?? this.textMessagesLanguage?.errorMessageBar, 'Close',{ horizontalPosition: 'end' ,  panelClass: ['alert-warning']});
  }


  getTranslation() {
    this.translateService.stream('error').subscribe(data => {
      this.textMessagesLanguage = data;
    })
  }
}
